// extracted by mini-css-extract-plugin
export var about_us = "index-module--about_us--cJxR7";
export var container = "index-module--container--6k4oY";
export var text_container = "index-module--text_container--lsXTd";
export var development = "index-module--development--vHsb+";
export var banner = "index-module--banner--0XB82";
export var title = "index-module--title--uLgr-";
export var text = "index-module--text--OiR3X";
export var flex_box = "index-module--flex_box--40-Zf";
export var flex_item = "index-module--flex_item--I3Pde";
export var time = "index-module--time--wDsmV";
export var intro = "index-module--intro--nqiRL";
export var details = "index-module--details--S8QbF";
export var detail = "index-module--detail--Woy8i";